import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {reverse} from 'rambda'

import Home from './pages/Home'
import Shop from './pages/Shop'
import TheLastDayOfRain from './pages/shop/TheLastDayOfRain';
import Portfolio from './pages/Portfolio';
import PortfolioProject from './pages/PortfolioProject';
import About from "./pages/About";
import Contact from "./pages/Contact";
import NotFound from './pages/NotFound'

export type PageID = 'home' | 'shop' | 'shop-the-last-day-of-rain' | 'portfolio' | 'portfolio-section' | 'portfolio-project' | 'about' | 'contact'

type Page = {
  id: PageID,
  path: (params?: {[key: string]: string}) => string,
  title: string
  component: React.FC
  redirect?: string
}

export const pages: Page[] = [
  {
    id: 'home',
    path: () => '/',
    title: '',
    component: Home,
    redirect: '/portfolio/section/all'
  },

  {
    id: 'shop',
    path: () => '/shop',
    title: 'Shop',
    component: Shop
  },

  {
    id: 'shop-the-last-day-of-rain',
    path: () => '/shop/the-last-day-of-rain',
    title: 'Shop - The Last Day of Rain',
    component: TheLastDayOfRain
  },

  {
    id: 'portfolio',
    path: () => '/portfolio',
    title: 'Portfolio',
    component: Home,
    redirect: '/portfolio/section/all'
  },

  {
    id: 'portfolio-section',
    // TODO: Add project name to path
    path: () => '/portfolio/section/:section',
    title: 'Portfolio',
    component: Home
  },

  {
    id: 'portfolio-project',
    // TODO: Add project name to path
    path: () => '/portfolio/project/:project',
    title: 'Portfolio',
    component: PortfolioProject
  },

  {
    id: 'about',
    path: () => '/about',
    title: 'About',
    component: About
  },

  {
    id: 'contact',
    path: () => '/contact',
    title: 'Contact',
    component: Contact
  }
]

export const getPage = (id: PageID): Page => {
  const page = pages.find(page => page.id === id)
  
  if (!page) {
    throw new Error(`The page ${id} is not declared. Please check Routes.tsx.`)
  }

  return page
}

export const findPage = (path: string): Page | null => {
  const page = pages.find(page => page.path() === path)
  return page || null
}

export function Routes () {
  return (
    <Switch>
      {reverse(pages).map(page => page.redirect ?
        <Route exact key={page.id} path={page.path()}>
          <Redirect to={page.redirect} />
        </Route>
      : (
        <Route exact key={page.id} path={page.path()} component={page.component} />
      ))}

      <Route path="/*" component={NotFound} />
    </Switch>
  )
}

export default Routes
