import styled from 'styled-components'
import { PortfolioImageKeys, defaultPortfolioImages, projects } from '../../data/portfolio'
import { breakpoints, fromMedium, fromSmall } from '../../styles/mediaQueries';
import { light } from '../../styles/colors';
import { emphasis } from '../../styles/fonts';

interface Props {
  images?: PortfolioImageKeys;
}

const ProjectOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 20, 59, 0.9);
  color: ${light};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-family: ${emphasis};
  opacity: 0;
  transition: opacity 0.3s;
  padding: 1em;
  text-align: center;
  font-weight: bold;
`;

/* https://www.smashingmagazine.com/2018/02/generated-content-grid-layout/ */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* Doesn't work */
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  width: 100vw;
  max-width: ${breakpoints.maxWidth}px;
  margin: 0 auto;
  padding: 1em;
  flex-wrap: wrap;
  grid-auto-flow: dense;

  ${fromSmall} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  ${fromMedium} {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
`

const GridItem = styled.a<{$spanColumns?: number, $preventCropping?: boolean}>`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    grid-column: span ${props => props.$spanColumns || 1};

  > img {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.$preventCropping ? 'contain' : 'cover'};
    image-rendering: crisp-edges;
  }

  &:hover ${ProjectOverlay}, &:focus ${ProjectOverlay} {
    opacity: 1;
  }
`

const getProject = (slug: string) => projects.find(project => project.slug === slug);

const getImageForProject = (projectSlug: string, imageSlug: string) => {
  const project = getProject(projectSlug);
  return project?.images.find(image => image.slug === imageSlug);
}

// should receive filters as a prop
export function PortfolioGrid ({ images = defaultPortfolioImages }: Props) {
  return (
    <GridContainer>
      {images.map(([project, image]) => {
        const imageData = getImageForProject(project, image);

        return imageData && !imageData.hideFromGallery ? (
          <GridItem href={`/portfolio/project/${project}?image=${image}`} $spanColumns={imageData.spanColumns} $preventCropping={imageData.preventCropping} key={image}>
            <img src={imageData.imageMidSize} alt={getProject(project)?.title} />
            <ProjectOverlay>{getProject(project)?.title}</ProjectOverlay>
          </GridItem>
        ) : null
      })}
    </GridContainer>
  )
}
