import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";
import { fromSmall } from "../styles/mediaQueries";
import { useRouteMatch } from "react-router-dom";
import { findPage } from "../Routes";
import SocialLinks from "./SocialLinks";
import Header from "./Header";
import Newsletter from "./Newsletter";
import { dark } from "../styles/colors";

interface Props {
  children: React.ReactNode;
  dynamicTitle?: string;
}

const loadAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 0.5rem 4rem;
  width: 100vw;
  min-height: 100vh;

  ${fromSmall} {
    padding: 1rem 0.5rem 8rem;
  }
`

const Main = styled.main`
  opacity: 0;
  animation: ${loadAnimation} 0.8s ease-in-out forwards;
  animation-delay: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Contact = styled.div`
  color: ${dark};
  margin: 2rem 0;
  font-size: 1.2rem;
`

interface Props {
  children: React.ReactNode
}

export default function Page ({ children, dynamicTitle }: Props) {
  const {path} = useRouteMatch()
  const pageTitle = dynamicTitle ? `${dynamicTitle} | ${findPage(path)?.title}` : findPage(path)?.title
  const title = pageTitle ? `${pageTitle} | Claudia Matosa` : 'Claudia Matosa'

  return (
    <StyledPage>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Header />

      <Main>
        {children}
      </Main>

      <Newsletter />
      <SocialLinks />

      <Contact>
        Enquiries: <strong>hello@claudiamatosa.com</strong>
      </Contact>
    </StyledPage>
  )
}
