import styled from 'styled-components';
import Page from '../components/Page'
import { breakpoints, fromTablet, toTablet } from '../styles/mediaQueries';
import { light } from '../styles/colors';

import BioImage from '../images/bio-pics/claudiamatosa-photo.jpg';
import links from '../data/links';

const FolioDetails = styled.section`
  max-width: ${breakpoints.maxWidth}px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 2rem;
  position: relative;
  
  ${fromTablet} {
    flex-direction: row;
    align-items: flex-start;
  }

  &:last-child {
    ${toTablet} {
      gap: 0;

      & > div:first-child {
        order: 2
      }

      & > div:last-child {
        order: 1
      }
    }
  }
`;

const Column = styled.div`
  flex: 1;
  padding: 1em 1em 0;

  ${fromTablet} {
    padding-bottom: 1em;
  }

  & > img {
    width: 100%;
    height: auto;
  }

  &:first-child {
    ${fromTablet} {
      padding-top: 1em;
      max-width: 28rem;
    }
  }

  &:last-child {
    max-width: 43rem;
    padding-top: 0;

    ${fromTablet} {
      padding-top: 0.5em;
    }
  }
`;

const MainImage = styled.figure`
  position: relative;
  margin: 0;

  ${toTablet} {
    width: 100vw;
    height: 30vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
  }

  & img {
    width: 100%;
  }

  & figcaption {
    opacity: 0.6;
    text-align: center;
    padding-top: 0.5em;

    ${toTablet} {
      display: none;
    }
  }
`;

const Details = styled.article`
  font-size: 1.3rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;

  ${fromTablet} {
    text-align: left;
  }

  ${toTablet} { 
    background-color: ${light};
    padding: 0 1em 0.5em;
    position: relative;
  }
`;

const Title = styled.h2`
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0.1em;
`;

const Subtitle = styled.p`
  margin-top: 0;
  opacity: 0.5;
  font-size: 1em;
`;

const SmallTitle = styled.h3`
  font-size: 1.3em;
  margin-top: 0.5em;
  margin-bottom: 0;
`;

const Description = styled.div`
  line-height: 1.3;
`;

const Links = styled.ul`
  list-style: none;
  padding: 0 0 1em;
  margin-bottom: 0;

  li {
    margin: 0.2em 0;
  }
`;

const Services = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  font-weight: 500;

  li {
    margin: 0.2em 0;
  }
`;

const socialLinks = [
  {
    title: 'Newsletter',
    link: links.substack,
  },
  {
    title: 'LinkedIn',
    link: links.linkedIn,
  },
  {
    title: 'Instagram',
    link: links.instagram,
  },
  {
    title: 'Cara',
    link: links.cara,
  },
  {
    title: 'Twitter',
    link: links.twitter,
  },
  {
    title: 'Bluesky',
    link: links.bluesky,
  }
]

export default function About() {
  return (
    <Page>
        <FolioDetails>
          <Column>
            <MainImage>
                <img src={BioImage} alt="Claudia Matosa - Illustrator"  />
                <figcaption>The most awkward photo she could find.</figcaption>
            </MainImage>
          </Column>
        
          <Column>
            <Details>
              <Title>About Claudia</Title>
              <Subtitle>If you're here for the answer, it's 42. 🐬</Subtitle>

              <Description>
                <p>Claudia is an <strong>illustrator and comic artist</strong> based in South London, with a love for (very) colourful images and all things science-fiction. Her inspiration comes from beautiful and sometimes strange <strong>architecture</strong>, from <strong>open nature and dramatic skies</strong>, and from <strong>people and pets</strong> all around her. Her work, although fictional, is often imbued with experiences from her childhood and early adult years in Portugal, where she comes from originally.</p>

                <p>She moved to London to pursue a different career, but soon realised that <strong>her passion was creating stories with images</strong>. She has a degree in Audiovisual and Multimedia and a post-graduation in Digital Art, and alongside freelance projects is currently working on her <strong>comic book series</strong>, <em>The Last Day of Rain</em>.</p>
              
                {/* <p>To date, she self-published six full-colour comics, participated in multiple anthologies with other artists (from WIP Comics and Distant Connections), and frequently attends fairs and conventions to find her crowd.</p> */}
              </Description>
            </Details>
          </Column>
        </FolioDetails>

        <FolioDetails>
          <Column>
            <Details>
              <SmallTitle>Socials</SmallTitle>
              <Links>
                {socialLinks.map((link) => (
                  <li key={link.title}>
                    <a href={link.link} target='_blank' rel='noreferrer'><i className="fa-solid fa-link"></i> {link.title}</a>
                  </li>
                ))}
              </Links>

              <SmallTitle>Next appearances</SmallTitle>  
              <Links>
                <li>
                  <a href="https://www.thoughtbubblefestival.com/comicconvention" target='_blank' rel='noreferrer'><i className="fa-solid fa-comment"></i> 16-17 November: Thought Bubble Festival</a>
                </li>
              </Links>
            </Details>
          </Column>

          <Column>
              <Details>

                <SmallTitle>Services</SmallTitle>

                <Services>
                  <li>Book & Album Covers</li>
                  <li>Character Design</li>
                  <li>Comic Book Art</li>
                  <li>Concept Art</li>
                  <li>Editorial Illustration</li>
                  <li>Picture Books</li>
                  <li>Scientific Illustration</li>
                  <li>Visual Development</li>
                </Services>

                <Description>
                  <p>Please do feel free to reach out with queries related to any other fields: new ideas are always welcome!</p>
                </Description>
              </Details>
          </Column>
        </FolioDetails>

        {/* Services provided */}

        {/* Contact form */}
    </Page>
  )
}
