const links = {
  art: '/art',
  comics: '/comics',
  code: '/code',
  resources: '/resources',
  shop: '/shop',
  shopify: 'https://shop.claudiamatosa.com',
  cara: 'https://cara.app/claudiamatosa',
  youtube: 'https://www.youtube.com/channel/UCgsQjXytgiM5zJy6kgbliMQ',
  instagram: 'https://instagram.com/claudiamatosa',
  twitter: 'https://twitter.com/claudiamatosa',
  tiktok: 'https://tiktok.com/@claudiamatosa',
  github: 'https://github.com/claudiamatosa',
  linkedIn: 'https://www.linkedin.com/in/claudiamatosa/',
  facebook: 'https://www.facebook.com/claudiamatosa.comics',
  artstation: 'https://www.artstation.com/claudiamatosa',
  deviantart: 'https://claudiamatosa.deviantart.com/',
  opensea: 'https://opensea.io/claudiamatosa',
  mastodon: 'https://mastodon.art/@claudiamatosa',
  medium: 'https://medium.com/@claudiamatosa',
  bluesky: 'https://bsky.app/profile/claudiamatosa.bsky.social',
  globalcomix: 'https://globalcomix.com/a/claudia-matosa-comics',
  substack: 'https://claudiamatosa.substack.com',
  kickstarter: 'https://www.kickstarter.com/projects/claudiamatosa/the-last-day-of-rain-3-4-sci-fi-romance-and-climate-change',
  email: 'mailto:hello@claudiamatosa.com',
}

export default links
