import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: 1rem 0;
  margin: 1rem auto;
`;

const Frame = styled.iframe`
  border: 1px solid white;
  background: white;
  width: 100%;
  height: auto;
`

export default function Newsletter () {
  return (
    <Container>
      <Frame
        title="substack"
        src="https://claudiamatosa.substack.com/embed"
        frameBorder="0"
        scrolling="no"
      ></Frame>
    </Container>
  )
}
