import { createGlobalStyle } from 'styled-components'
import {light, primaryDark, secondaryDark, secondary, dark, primary} from './styles/colors'
import { body, emphasis } from './styles/fonts'

// import background from './images/background.svg'
import { fromLarge, fromMedium, fromTablet } from './styles/mediaQueries'

import "react-image-gallery/styles/css/image-gallery.css";

/* background: url(${background}) ${dark} no-repeat center center scroll;
background-size: cover;
background-position: center -5vh; */

export const AppStyles = createGlobalStyle`
  html, body {
    overflow-x: hidden;
  }

  body {
    position: relative
  }

  html {
    box-sizing: border-box;
    font-size: 13px;
    min-height: 100vh;

    // Safe padding for devices with a camera notch: https://webkit.org/blog/7929/designing-websites-for-iphone-x/
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    color: ${dark};
    background-color: ${light};

    ${fromMedium} {
      font-size: 16px;
    }

    ${fromLarge} {
      font-size: 18px;
    }

    /* ${fromTablet} {
      background-size: 130vh auto;
    } */
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${body};
    font-weight: 400;
  }

  b {
    font-weight: 500;
  }

  h1, h2, h3, h4 {
    font-family: ${emphasis};
    font-weight: normal;
    color: ${primaryDark};
  }

  h1 b, h2 b, h3 b, h4 b {
    font-weight: 600;
  }

  a, p a, aside a {
    &, &:link {
      color: inherit;
      text-decoration: none;
      color: ${primary};
      transition: color 0.2s ease-in-out;
      font-weight: 500;
    }

    &:hover, &:focus {
      color: ${secondaryDark};
    }
  }

  .image-gallery-right-nav, .image-gallery-left-nav {
    transform: scale(0.8) translateY(-50%);
    transform-origin: center;
  }

  .image-gallery-icon {
    & > svg {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      color: ${secondary};
    }
  }

  .image-gallery-image {
    border-radius: 1em;
  }

  .image-gallery-thumbnail {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 1em;

    img {
      border-radius: 0.85em;
    }

    &.active, &:focus, &:hover {
      border-color: ${secondary};
    }
  }
  
  /* Cookie consent banner */
  .cc-window {
    font-family: ${body};
    font-weight: 400;
    
    background-color: rgba(13, 25, 73, 0.8) !important;
    padding: 0.5em  !important;
    border-radius: 0  !important;
    margin-bottom: -1px !important;

    .cc-message {
      font-size: 0.9rem;
      line-height: 1.2;
      text-align: center;
    }

    .cc-compliance {
      justify-content: center !important;
    }
      
    .cc-compliance .cc-btn {
      background-color: ${primary};
      color: ${light};
      opacity: 0.8;
      font-size: 0.9rem;
      font-weight: 600;
      padding: 0;
      width: auto;
      transition: opacity 0.2s ease-in-out;

      &:hover, &:focus {
        background-color: ${primary};
        opacity: 1;
      }
    }
  }
`
