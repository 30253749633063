import Page from '../components/Page'
import AboutMe from '../components/AboutMe'
import ProductsTeaser from '../components/ProductsTeaser'
import { PortfolioWithNavigation } from '../components/portfolio/PortfolioWithNavigation'
// import Kickstarter from '../components/Kickstarter'

function Home () {
  return (
    <Page>
      <PortfolioWithNavigation />      

      <AboutMe showTitle={false} />

      <ProductsTeaser />

    </Page>
  )
}

export default Home
