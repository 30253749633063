export const breakpoints = {
  mini: 200,
  small: 500,
  medium: 795, // Navigation bar jumps to top, header images disappear
  tablet: 900,
  desktop: 1024,
  large: 1200,
  xlarge: 1650,
  maxWidth: 1800,
  wideScreen: 2100,
  veryLarge: 3500
}

export const fromMini = `@media (min-width: ${breakpoints.mini}px)`
export const toSmall = `@media (max-width: ${breakpoints.small}px)`
export const fromSmall = `@media (min-width: ${breakpoints.small}px)`
export const toMedium = `@media (max-width: ${breakpoints.medium}px)`
export const fromMedium = `@media (min-width: ${breakpoints.medium}px)`
export const toTablet = `@media (max-width: ${breakpoints.tablet}px)`
export const fromTablet = `@media (min-width: ${breakpoints.tablet}px)`
export const fromDesktop = `@media (min-width: ${breakpoints.desktop}px)`
export const fromLarge = `@media (min-width: ${breakpoints.large}px)`
export const fromXlarge = `@media (min-width: ${breakpoints.xlarge}px)`
export const fromMaxWidth = `@media (min-width: ${breakpoints.maxWidth}px)`
export const fromWideScreen = `@media (min-width: ${breakpoints.wideScreen}px)`
export const fromVeryLarge = `@media (min-width: ${breakpoints.veryLarge}px)`
