import { useQuery } from 'react-query'
import styled from 'styled-components'
import { getProducts as getProductsFromShopify } from '../data/shopify'

import { Product as ShopProduct } from '../data/shop'

import Product from '../components/shop/Product'
import { useSortedProducts } from '../utils/products'
import { ButtonLinkRouter } from './ui/Button'
import { fromMedium } from '../styles/mediaQueries'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem auto 6.5rem;

  ${fromMedium} {
    margin-top: 1rem;
  }
`;

const Products = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100vw;
  max-width: 1800px;
`

const ButtonsContainer = styled.div`
  text-align: center;
  font-size: 1.2rem;
`

const maxProducts = 3;

function ProductsTeaser () {
  const {data: shopifyProducts} = useQuery<ShopProduct[], any>('shopify-products', () => getProductsFromShopify())
  const sortedProducts = useSortedProducts(shopifyProducts).slice(0, maxProducts)

  return (
    <Container>
      {sortedProducts && (
        <Products>
          {sortedProducts.map(product => (
            <Product product={product} key={product.id} layout="compact" />
          ))}
        </Products>
      )}

      <ButtonsContainer>
        <ButtonLinkRouter to="/shop">Shop for comics, prints & stickers!</ButtonLinkRouter>
      </ButtonsContainer>
    </Container>
  )
}

export default ProductsTeaser
