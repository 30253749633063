import styled from "styled-components"
import links from "../data/links"
import { light, primary, secondary } from "../styles/colors"
import caraLogo from "../components/icons/cara-logo.png";

interface Props {
  short?: boolean
  marginTop?: boolean
}

const Container = styled.nav<{$marginTop: boolean}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.5em;
  gap: 0.3em;
  margin-top: ${(props) => props.$marginTop ? '2rem' : '0'};
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 200;
`

const Link = styled.a`
  color: ${light};
  background-color: ${primary};
  border-radius: 1.2em;
  position: relative;
  display: block;
  padding: 0.5em;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5em;
    transform: scaleX(1.1) scaleY(1.1) rotate(2deg) translate(0.05em, 0.05em);
    background-color: ${secondary};
    z-index: -1;
    transition: transform 0.5s;
  }

  img {
    width: 1.2em;
    margin-bottom: -0.2em;
  }

  &:link, &:visited {
    color: ${light};
  }

  &:hover, &:focus {
    color: ${light};

    & > .background-blob {
      transform: scaleX(1.2) scaleY(1.1);
    }
  }
`

function SocialLink ({link, title, icon}: {link: string, title: string, icon: string}) {
  return (
    <Link href={link} target="_blank" rel="noopener noreferrer" title={title}>
      {icon === 'cara' ? (
        <img src={caraLogo} alt="Cara.app" />
      ) : <i className={icon} />}
    </Link>
  )
}

export default function SocialLinks ({ short = false, marginTop = true }: Readonly<Props>) {
  return (
    <Container $marginTop={marginTop}>
      <SocialLink link={links.instagram} title="Instagram" icon="fa-brands fa-instagram" />
      <SocialLink link={links.twitter} title="Twitter" icon="fa-brands fa-twitter" />
      <SocialLink link={links.cara} title="Cara" icon="cara" />

      {!short && (
        <>
          <SocialLink link={links.substack} title="Substack" icon="fa-solid fa-pen-nib" />
          <SocialLink link={links.globalcomix} title="GlobalComix" icon="fa-solid fa-globe" />
          <SocialLink link={links.bluesky} title="Bluesky" icon="fa-solid fa-cloud" />
          <SocialLink link={links.youtube} title="YouTube" icon="fa-brands fa-youtube" />
          <SocialLink link={links.mastodon} title="Mastodon" icon="fa-brands fa-mastodon" />
          <SocialLink link={links.facebook} title="Facebook" icon="fa-brands fa-facebook" />
        </>
      )}

      <SocialLink link={links.email} title="Email Me" icon="fa-solid fa-envelope" />

      {/* <SocialLink link={links.tiktok} title="TikTok" icon="fa-brands fa-tiktok" /> */}
      {/* <SocialLink link={links.medium} title="Medium Blog" icon="fa-brands fa-medium" /> */}
      {/* <SocialLink link={links.artstation} title="ArtStation" icon="fa-brands fa-artstation" /> */}
      {/* <SocialLink link={links.deviantart} title="DeviantArt" icon="fa-brands fa-deviantart" /> */}
      {/* <SocialLink link={links.opensea} title="OpenSea" icon="fa-solid fa-sailboat" /> */}
      {/* <SocialLink link={links.github} title="GitHub" icon="fa-brands fa-github" /> */}
      {/* <SocialLink link={links.linkedIn} title="LinkedIn" icon="fa-brands fa-linkedin" /> */}
    </Container>
  )
}
