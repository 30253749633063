/*
  Filters by type of illustration: book cover design, concept art, sketches, comics, others, all, project name
*/

import styled from 'styled-components';
import { IllustrationType, PortfolioImageKeys, projects } from '../../data/portfolio';
import { PortfolioGrid } from './PortfolioGrid';
import { fromMedium } from '../../styles/mediaQueries';
import { light, primary } from '../../styles/colors';
import { NavLink, useParams } from 'react-router-dom';

interface RouteParams {
  section: string
}

const availableFilters = [
  {
    slug: 'illustration',
    name: 'Illustration',
    illustrationTypes: [IllustrationType.FullPageIllustration, IllustrationType.Editorial, IllustrationType.Others, IllustrationType.ConceptArt, IllustrationType.CharacterDesign]
  }, {
    slug: 'book-covers',
    name: 'Book Covers',
    illustrationTypes: [IllustrationType.BookCoverDesign]
  }, {
    slug: 'comics',
    name: 'Comics',
    illustrationTypes: [IllustrationType.ComicBookPages]
  },
  {
    slug: 'characters',
    name: 'Characters',
    illustrationTypes: [IllustrationType.CharacterDesign]
  }, {
    slug: 'environments',
    name: 'Environments',
    illustrationTypes: [IllustrationType.EnvironmentDesign]
  }, {
    slug: 'sketches',
    name: 'Sketches',
    illustrationTypes: [IllustrationType.Sketches]
  }
  // Add: sketches, b&w
];

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Filters = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  list-style: none;
  padding: 0 1em;
  margin-top: 1.5em;
  flex-wrap: wrap;
  max-width: 100%;

  li {
    margin: 0;
    padding: 0;
  }

  a, a:visited {
    color: ${primary};
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
    transition-property: opacity, color;
  }

  a:hover, a:focus {
    opacity: 1;
  }

  a.active {
    padding: 0 0.4em;
    background-color: ${primary};
    color: ${light};
    border-radius: 0.3em 0.6em;
  }

  ${fromMedium} {
    margin-top: 0;
  }
`;

function getSectionFilters(section: string) {
  return availableFilters.find(filter => filter.slug === section);
}

function getImagesForSection(section: string) {
  const sectionFilter = getSectionFilters(section);
  const illustrationTypes = sectionFilter?.illustrationTypes;
  const images: PortfolioImageKeys = [];

  if (!illustrationTypes) {
    return;
  }

  projects.forEach(project => {
    project.images.forEach(image => {
      if (illustrationTypes.some((type) => image.type.includes(type))) {
        images.push([project.slug, image.slug]);
      }
    });
  });

  return images;
}

export function PortfolioWithNavigation() {
  const routeParams = useParams<RouteParams>();
  const imagesForSection = routeParams.section && routeParams.section !== 'all' ? getImagesForSection(routeParams.section) : undefined;;

  return (
    <Container>
      <Filters>
        <li><NavLink to="/portfolio/section/all">All</NavLink></li>

        {availableFilters.map(({slug, name, illustrationTypes}) => (
          <li>
            <NavLink to={`/portfolio/section/${slug}`}>
              {name}
            </NavLink>
          </li>
        ))}
      </Filters>

      <PortfolioGrid images={imagesForSection} />
    </Container>
  )
}
