import styled from "styled-components";
import TextItem from "./ui/TextItem";
import SocialLinks from "./SocialLinks";

interface Props {
  showTitle?: boolean
  first?: boolean
  showIntro?: boolean
  showSocials?: boolean
  showContactInfo?: boolean
}

const Emphasis = styled.span`
  font-weight: 500;
`;

export default function AboutMe ({first = false, showTitle = true, showIntro = true, showContactInfo = true, showSocials = false}: Readonly<Props>) {
  return (
    <TextItem first={first}>
      {/* {showTitle && <h3>A little about me</h3>} */}
      {showIntro && <p>Claudia is a London-based illustrator and comic artist with a passion for <Emphasis>bold colours and science-fiction stories</Emphasis> (as you probably have noticed).</p>}

      {showContactInfo && <>
        <p>Should you be interested hiring her for a project or selling her work in your shop, she's available via <strong>hello@claudiamatosa.com</strong>.</p>
        <p>You're also very welcome to <a href="https://claudiamatosa.com/newsletter" target="_blank" rel="noopener noreferrer">subscribe to the newsletter</a> and follow work in progress, book releases and reviews!</p>
      </>}

      {showSocials && <SocialLinks short />}
    </TextItem>
  )
}
